import React from 'react'
import { AnimatePresence } from 'framer-motion'
import "./src/common/global.css"

// import scrollTo from 'gatsby-plugin-smoothscroll';

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
)
export const onRouteUpdate = () => {
  const TRANSITION_DELAY = 0.35 * 1000 * 2
  
  if ((location.pathname === '/' && location.hash) || (location.pathname === '/en/' && location.hash)) {
    document.getElementsByTagName('body')[0].classList.add("hide");
    window.setTimeout(() => {
      if(document.getElementById(location.hash.substring(1))) document.getElementById(location.hash.substring(1)).scrollIntoView()
      window.setTimeout(() => document.getElementsByTagName('body')[0].classList.remove("hide"), 200)
    }, TRANSITION_DELAY)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if(!prevRouterProps) return false;
  const oldLocation = prevRouterProps.location
  const TRANSITION_DELAY = 0.35 * 1000 * 2

  if ((location.pathname === '/' && oldLocation.pathname.startsWith('/progetti')) || (location.pathname === '/en/' && oldLocation.pathname.startsWith('/en/projects'))) {
    document.getElementsByTagName('body')[0].classList.add("hide");
    const pr = oldLocation.pathname.split('/')

    window.setTimeout(() => {
      if(document.getElementById(pr[pr.length - 1])) document.getElementById(pr[pr.length - 1]).scrollIntoView()
      window.setTimeout(() => document.getElementsByTagName('body')[0].classList.remove("hide"), 200)
    }, TRANSITION_DELAY)
  }
  if ((location.pathname === '/' && !oldLocation.pathname.startsWith('/progetti')) || (location.pathname === '/en/' && !oldLocation.pathname.startsWith('/en/projects'))) {
    document.getElementsByTagName('body')[0].classList.remove("hide");
  }
  if (location.pathname !== '/' && location.pathname !== '/en/') {
//    console.log('NON HOME')
    document.getElementsByTagName('body')[0].classList.add("hide");
    document.getElementsByTagName('body')[0].scrollIntoView()
//    window.setTimeout(() => document.getElementsByTagName('body')[0].classList.remove("hide"), 200)
  }
  return false
}

/*
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if(!prevRouterProps) return false;
  const oldLocation = prevRouterProps.location
  console.log(location)
  console.log(location.pathname.startsWith('/progetti'))
  console.log(oldLocation)
  // const TRANSITION_DELAY = 0.35 * 1000 * 2
  const TRANSITION_DELAY = 1 * 1000 * 2

  if (location.pathname === '/' && oldLocation.pathname.startsWith('/progetti')) {
    // window.setTimeout(() => document.getElementById('top').classList.add("temp"), TRANSITION_DELAY)
    document.getElementsByTagName('body')[0].classList.add("hide");

    
    const pr = oldLocation.pathname.split('/')
    // window.setTimeout(() => scrollTo(`#${pr[pr.length - 1]}`), TRANSITION_DELAY)
    window.setTimeout(() => {
      console.log('a',document.getElementById('top'))
      document.getElementById('top').classList.remove("snap");
      console.log('b', pr[pr.length - 1], document.getElementById(pr[pr.length - 1]).offsetTop)
      window.scrollTo(0, document.getElementById(pr[pr.length - 1]).offsetTop);
      // document.getElementById('top').classList.add("snap");
      // document.getElementsByTagName('body')[0].classList.remove("hide");
        document.getElementsByTagName('body')[0].classList.remove("hide")
      // window.setTimeout(() => {
      //   document.getElementById('top').classList.add("snap");
      // }, 500)

    }, TRANSITION_DELAY)

    // console.log('vengo dal progetto "', pr[pr.length - 1])
    // const coso = document.getElementById('persistenza')
    // const coso = document.querySelector('#persistenza').offsetTop
    // const coso = document.querySelector(`#${pr[pr.length - 1]}`).offsetTop
    // document.addEventListener("DOMContentLoaded", function(event) { 
    //   console.log('el', document.getElementById('persistenza').offsetTop)
    // });
    // window.setTimeout(() => console.log('el', document.getElementById('persistenza').offsetTop), TRANSITION_DELAY)
    

  }
  return false
}
*/

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
//   prevRouterProps
// }) => {
//   // transition duration from `layout.js` * 1000 to get time in ms
//   // * 2 for exit + enter animation
//   const TRANSITION_DELAY = 0.35 * 1000 * 2
//   console.log(location)
//   console.log(prevRouterProps)
//   // if it's a "normal" route
//   if (location.action === "PUSH") {
//     // const savedPosition = getSavedScrollPosition({pathname: `sotto`})
//     // window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
//     // window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
//   }
//   // if we used the browser's forwards or back button
//   else {
//     const savedPosition = getSavedScrollPosition(location) || [0, 0]
//     console.log('savedPosition', savedPosition)
//     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
//   }
//   return false
// }
