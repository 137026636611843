exports.components = {
  "component---src-old-templates-blog-template-js": () => import("./../../../src/old/templates/blog/template.js" /* webpackChunkName: "component---src-old-templates-blog-template-js" */),
  "component---src-old-templates-dev-template-js": () => import("./../../../src/old/templates/dev/template.js" /* webpackChunkName: "component---src-old-templates-dev-template-js" */),
  "component---src-old-templates-scritti-template-js": () => import("./../../../src/old/templates/scritti/template.js" /* webpackChunkName: "component---src-old-templates-scritti-template-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-index-libro-js": () => import("./../../../src/pages/admin/indexLibro.js" /* webpackChunkName: "component---src-pages-admin-index-libro-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-old-js": () => import("./../../../src/pages/chi_old.js" /* webpackChunkName: "component---src-pages-chi-old-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pagina-1-js": () => import("./../../../src/pages/pagina1.js" /* webpackChunkName: "component---src-pages-pagina-1-js" */),
  "component---src-pages-pagina-2-js": () => import("./../../../src/pages/pagina2.js" /* webpackChunkName: "component---src-pages-pagina-2-js" */),
  "component---src-versions-caleb-template-contact-js": () => import("./../../../src/versions/caleb/template/contact.js" /* webpackChunkName: "component---src-versions-caleb-template-contact-js" */),
  "component---src-versions-caleb-template-news-js": () => import("./../../../src/versions/caleb/template/news.js" /* webpackChunkName: "component---src-versions-caleb-template-news-js" */),
  "component---src-versions-caleb-template-page-js": () => import("./../../../src/versions/caleb/template/page.js" /* webpackChunkName: "component---src-versions-caleb-template-page-js" */),
  "component---src-versions-caleb-template-project-js": () => import("./../../../src/versions/caleb/template/project.js" /* webpackChunkName: "component---src-versions-caleb-template-project-js" */)
}

